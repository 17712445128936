@media (max-width: 1440px) {
  .slider-area .single-slide-item:after {
    width: 615px;
  }
  .slider-area .homepage-slide1 .owl-dots {
    right: 137px;
  }
  .mixer-area2 .owl-dots {
    right: -90px;
  }
}

@media (max-width: 1366px) {
  .slider-area .single-slide-item:after {
    width: 579px;
  }
  .slider-area .homepage-slide1 .owl-dots {
    right: 60px;
  }
}

@media (max-width: 1280px) {
  .slider-area .single-slide-item:after {
    width: 535px;
  }
  .entry-area:after {
    width: 417px;
  }
  .mixer-area2 .fun-content-slide,
  .helping-area2 .helping-form {
    padding-top: 100px;
    padding-left: 100px;
  }
  .mixer-area2 .owl-dots {
    right: -40px;
  }
  .mixer-area2:after {
    height: 683px;
  }
}

.header-area .mobile-menu-toggler {
  display: none;
}

.header-area .nav-toggler {
  display: none;
}

@media (min-width: 700px) and (max-width: 1198px) {
  .header-area .mini-navbar {
    display: none;
    position: absolute;
    top: 60px;
    /* right: 20px; */
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 4px;
    width: 200px;
    z-index: 99999;
  }
}

@media (min-width: 0px) and (max-width: 699px) {
  .header-area .mini-navbar {
    display: none;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 4px;
    width: 200px;
    z-index: 99999;
  }
}
/*=== 1199px ===*/
@media (max-width: 1199px) {
  .header-area .header-top .ostion-top-wrap .ostion-menu .ostion-menu-content {
    /* display: inline-block;
    overflow: hidden; */
    display: none;
  }

  /* .header-area {
    display: flex;
    justify-content: space-evenly;
  } */

  .header-area .nav-toggler {
    display: block;
  }

  .header-area .mobile-menu-toggler {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  .header-area .menu-icon {
    width: 100%;
    height: 100%;
  }

  .header-area .mini-navbar.show {
    top: 120px;
    display: block;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
    cursor: pointer;
  }

  .header-area .nav-mobile {
    padding: 10px 0;
  }

  .header-area .nav-mobile a {
    display: block;
    padding: 8px 15px;
    color: #333;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.2s;
  }

  .header-area .nav-mobile a:hover {
    background-color: #f0f0f0;
  }

  .header-area .nav-mobile {
    padding: 15px 0; /* Increased padding for better spacing */
  }

  .header-area .nav-mobile .nav-item {
    margin-bottom: 10px; /* Add some vertical spacing between items */
  }

  .header-area .nav-mobile .nav-link {
    color: #333;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.2s, color 0.2s;
  }

  .header-area .nav-mobile .nav-link:hover {
    background-color: #f0f0f0;
    color: #007bff; /* Change link color on hover */
  }

  .header-area .nav-mobile li:hover {
    color: #007bff; /* Change link color on hover */
  }
}
/* 
@media (min-width: 570px) and (max-width: 719px) {
  .header-area .header-top .ostion-top-wrap .ostion-logo {
    height: 100px;
    display: table;
    vertical-align: middle;
  }
  .header-area .header-top .ostion-top-wrap .header-btn .main-btn {
    top: 5px;
    max-height: 50px;
    overflow: hidden;
  }
} */

@media (min-width: 291px) and (max-width: 384px) {
  .header-area .header-top .ostion-top-wrap .header-btn .main-btn {
    /* top: 2px; */
    max-height: 50px;
    width: 158px;
  }

  .header-area .mobile-menu-toggler {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-left: -2px !important;
  }
}

/* @media (min-width: 350px) and (max-width: 380px) {
  .header-area .header-top .ostion-top-wrap .header-btn .main-btn {
    top: 15px;
    max-height: 50px;
  }
} */

/* @media (min-width: 381px) and (max-width: 386px) {
  .header-area .header-top .ostion-top-wrap .header-btn .main-btn {
    top: 15px;
    max-height: 50px;
  }
} */

/* @media (min-width: 290px) and (max-width: 385px) {
  .header-area .header-top .ostion-top-wrap .header-btn .main-btn {
    top: 15px;
    max-height: 50px;
  }
} */

@media (min-width: 570px) and (max-width: 1199px) {
  .header-area .header-top {
    text-align: center;
  }

  .header-area .header-top .container {
    display: inline-block;
    width: 340px;
    height: 200px;
  }

  .header-area .header-top .ostion-top-wrap .header-btn .main-btn {
    top: 2px;
    max-height: 50px;
    width: 160px;
  }
}

@media (min-width: 0px) and (max-width: 290px) {
  .header-area .header-top .ostion-top-wrap .ostion-logo a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
  }
  .header-area .header-top .ostion-top-wrap .header-btn .main-btn {
    display: none;
  }
}
/*=== 1024px ===*/
@media (max-width: 1024px) {
  .slider-area .slider-heading .slider__title {
    font-size: 86px;
    line-height: 94px;
  }
  .slider-area .single-slide-item:after {
    width: 423px;
  }
  .mixer-area2:after {
    height: 791px;
  }
}

/*-----------------------------
    991px tablet device
------------------------------*/
@media (max-width: 991px) {
  .slider-area .single-slide-item:after {
    width: 408px;
  }
  .slider-area .single-slide-item .col-md-7 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mixer-area2 {
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 0px;
  }
  .mixer-area3 .section-heading {
    padding-right: 0;
    padding-left: 0;
  }
  .fun-content-wrap .fun-content .fun-item {
    margin-right: 0;
  }
  .hiw-area .hiw-item .hiw-item-img-box .hiw-title {
    padding: 0 0;
    font-size: 26px;
    line-height: 30px;
  }
}

/*-----------------------------
    768px ipad device
------------------------------*/
@media (max-width: 768px) {
  .right-image {
    width: 100%; /* Set the width to 100% to make it responsive */
    margin-right: 0; /* Remove right margin for better alignment */
  }

  /* You can also adjust other properties as needed */
  .right-image iframe {
    height: 250px; /* Adjust the height as needed for smaller screens */
  }
}

/*-----------------------------
    767px
------------------------------*/
@media (max-width: 767px) {
  .slider-area .single-slide-item .col-md-7 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .slider-area .slider-heading .slider__title {
    font-size: 76px;
    line-height: 90px;
  }
}
/*-----------------------------
    576px
------------------------------*/
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
/*-----------------------------
    575px
------------------------------*/
@media (max-width: 575px) {
  .slider-area .homepage-slide1 .owl-dots {
    right: 20px;
  }
}
/*-----------------------------
    480px
------------------------------*/
@media (max-width: 480px) {
  .slider-area .single-slide-item {
    height: 750px;
  }
  .slider-area .slider-heading .slider__title {
    font-size: 65px;
    line-height: 80px;
  }
  .slider-area2 .single-slide-item {
    height: 1024px;
  }
  .give-area .give-title-box .section__title {
    font-size: 40px;
  }
}
/*-----------------------------
    425px
------------------------------*/
@media (max-width: 425px) {
  .slider-area .single-slide-item {
    height: 650px;
  }
  .slider-area .slider-heading .slider__title {
    font-size: 55px;
    line-height: 70px;
  }
  .slider-area2 .single-slide-item {
    height: 1024px;
  }
  .mixer-area .mixer-heading .section__title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 44px;
  }
  .mixer-area2 .fun-content-slide .fun-slide-item .funslide__text {
    font-size: 36px;
    line-height: 45px;
  }
}
/*-----------------------------
    375px
------------------------------*/
@media (min-width: 320px) and (max-width: 479px) {
}

/*-----------------------------
    375px
------------------------------*/
@media (max-width: 375px) {
  .section-heading .section__title {
    font-size: 36px;
    line-height: 38px;
  }
  .slider-area .homepage-slide1 .owl-dots {
    top: auto;
    bottom: 20px;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  .slider-area .homepage-slide1 .owl-dots div {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 15px;
  }
  .slider-area2 .homepage-slide1 .owl-dots {
    bottom: 128px;
  }
}
